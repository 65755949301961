* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.footerTopContainer {
    padding: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../Images/foodBg.jpg');
    background-position: center;
    background-size: cover;
    color: #fff;
    border-bottom: 1px solid rgb(238, 236, 236);
}


.footerTopContainer .footerLogoInfo {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footerTopContainer .footerLogoInfo img {
    width: 50%;
}


.footerTopContainer .footerLogoInfo p {
    font-size: 13px;
    text-align: center;
}

.footerTopContainer .footerLogoInfo .footerLogoIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.footerTopContainer .footerLogoInfo .footerLogoIcons i {
    padding: 10px;
    border: 2px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    border-radius: 50%;
}

.footerContainer {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../Images/foodBg.jpg');
    background-position: center;
    background-size: cover;
    color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
}

.footerBox1 {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}

/* .footerBox1 img{
  width: 100%;
} */

.footerBox1 .footerIcon1 {
    display: flex;
    justify-content: center;
    gap: 15px
}

.footerBox1 .footerIcon2 {
    display: flex;
    gap: 15px
}

.footerBox1 .footerIcons i {
    color: #eb500e;
    font-size: 13px;
    background-color: #ffffff;
    padding: 8px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.footerBox2 {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}

.footerBox2 div {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 15px;
    border-bottom: 1px solid rgb(238, 236, 236);
    padding-bottom: 10px;
}

.footerBox3 {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.footerBox3 ul {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 20px;
}

.footerBox3 ul li {
    list-style-type: none;
}

.footerBox4 {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}

.footerBox4 div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.mapBox iframe {
    height: 300px;
    width: 100%
}

.footerSubContainer {
    background-color: #eb500e;
    border-top: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.footerSubContainer h3 {
    font-size: 15px;
    color: #ffffff;

}


@media (max-width: 1200px) {
    .footerContainer{
        padding: 30px 60px;
        gap: 40px;
        flex-wrap: wrap;
    }
    .footerBox1{
        width: 30%;
    }

    .footerBox2{
        width: 30%;
        align-items: end;
    }

    .footerBox3{
        width: 30%;
        align-items: center;
    }
    .footerBox3 ul{
        align-items: center;
        justify-content: start;
    }
    .footerBox4{
        width: 100%;
    }

    .mapBox{
        width: 100%;
    }
    .mapBox iframe {
        width: 100%;
    }
}
@media (max-width: 768px) {
.footerTopContainer .footerLogoInfo{
        width: 100%;
    }

    .footerTopContainer .footerLogoInfo img{
        width: 50%;
    }

    .footerContainer{
        flex-direction: column;
        padding: 30px;
        gap: 40px;
    }

    .footerBox1{
        width: 100%;
    }

    .footerBox2{
        width: 100%;
        align-items: start;
    }

    .footerBox3{
        width: 100%;
        align-items: start;
    }
    .footerBox3 ul{
        align-items: start;
        justify-content: start;
    }
    .footerBox4{
        width: 100%;
    }
}
@media (max-width: 480px) {
    .footerTopContainer .footerLogoInfo{
        width: 100%;
    }

    .footerTopContainer .footerLogoInfo img{
        width: 100%;
    }

    .footerContainer{
        flex-direction: column;
        padding: 30px;
        gap: 40px;
    }

    .footerBox1{
        width: 100%;
    }

    .footerBox2{
        width: 100%;
    }

    .footerBox3{
        width: 100%;
        align-items: start;
    }
    .footerBox3 ul{
        align-items: start;
        justify-content: start;
    }
    .footerBox4{
        width: 100%;
    }
} 