@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.headerBar {
    height: 40vh;
    width: 100%;
    display: flex;
    /* padding-top: 80px; */
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('../../Images/cafeLights.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.headerBar h1 {
    color: #fff;
    font-size: 30px;
    margin-top: 80px;
    border-bottom: 4px solid #eb500e;
    padding-bottom: 10px;
}


.galleryContainer {
    width: 80%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;

    display: grid;
    grid-template-areas: "img1 img2 img2 img3"
                         "img1 img4 img5 img6"
                         "img7 img7 img8 img6";
    gap: 20px;
}

.galleryContainer .galleryBox img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

#gbox-1 {
    grid-area: img1;
}

#gbox-2 {
    grid-area: img2;
    height: 200px;
}

#gbox-3 {
    grid-area: img3;
}

#gbox-4 {
    grid-area: img4;
}

#gbox-5 {
    grid-area: img5;
}

#gbox-6 {
    grid-area: img6;
}

#gbox-7 {
    grid-area: img7;
    height: 300px;
}

#gbox-8 {
    grid-area: img8;
}

@media (max-width: 1200px) {
    
}
@media (max-width: 768px) {
    .galleryContainer {
        width: 100%;
        margin: auto;
        padding: 40px 30px;
        display: grid;
        grid-template-areas: "img1 img2 img3"
                             "img4 img5 img6"
                             "img7 img7 img8";
        gap: 20px;
    }
    .galleryBox{

    }

}
@media (max-width: 480px) {
    .galleryContainer{
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
} 