@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}


/* home page */
main {
    height: 100vh;
    /* padding-top: 50px;
    padding-bottom: 50px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('../../Images/bgImg.jpg'); */
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url('../../Images/cafeLights.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

main .container {
    display: flex;
    width: 80%;
}

main .container .box1 {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 50px;
}

main .container .box1 i {
    color: #ffffff;
    font-size: 18px;
    background-color: #eb500e;
    padding: 15px;
    border: 1px solid #eb500e;
    border-radius: 3px;
}

main .container .box3 {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 120px;

}

main .container .box3 .vertical-button {
    display: inline-block;
    transform: rotate(90deg);
    color: #ffffff;
    background-color: #eb500e;
    border: none;
    padding: 15px 15px;
}

main .container .box3 .vertical-button a {
    background-color: transparent;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
}

.vertical-button button {
    padding: 20px 20px;
    writing-mode: vertical-lr;
    /* Set vertical writing mode */
    transform: rotate(180deg);
    /* Rotate the button back to its normal orientation */
}

main .container .box3 img {
    width: 100px;
    transform: rotate(90deg);
    /* Rotate the image 90 degrees clockwise */
}

main .container .box2 {
    padding-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}

main .container .box2 h1 {
    color: #ffffff;
    font-size: 60px;
}

main .container .box2 h2 {
    color: #ffffff;
    font-size: 20px;
}

main .container .box2 .logoImg {
    height: 150px;
}

main .container .box2 .undi {
    width: 400px;
    height: 15px;
    animation: zigzag 2s linear infinite;
}

@keyframes zigzag {
    0% {
        transform: scaleX(0);
    }

    25% {
        transform: scaleX(0.5);
    }

    50% {
        transform: scaleX(1);
    }

    75% {
        transform: scaleX(0.5);
    }

    100% {
        transform: scaleX(0);
    }
}


main .container .box2 p {
    color: #ffffff;
    font-size: 15px;
    text-align: center;
}

main .container .box2 button {
    width: 200px;
    padding: 10px 15px;
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    cursor: pointer;
}

/* home about section */
.aboutContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    font-family: "Poppins", sans-serif;
    padding-top: 60px;
    padding-bottom: 60px;
}

.aboutImg {
    width: 40%;
}

.aboutImg img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    border-left: 10px solid #eb500e;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.aboutInfo {
    width: 40%;
    padding: 20px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    gap: 20px;
}

.aboutInfo img {
    width: 80px;
}

.aboutInfo p {
    font-size: 15px;
}

.aboutInfo button {
    padding: 10px 10px;
    width: 40%;
    background-color: rgb(235, 80, 14);
    color: #ffffff;
    border: none;
    border-left: 5px solid #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}

/* @media (max-width: 1200px) {}
@media (max-width: 768px) {}
@media (max-width: 480px) {} */



@media (max-width: 1200px) {
    .box2{
        width: 50%;
    }
    main .container .box2 .logoImg{
        height: 100px;
    }
    main .container .box2 .undi{
        width: 300px;
    }
    .aboutContainer{
        flex-direction: column;
        padding: 30px;
        gap: 20px;
    }

    .aboutImg{
        width: 100%;
    }

    .aboutInfo{
        width: 100%;
    }

    .aboutInfo button{
        width: 100%;
    }

    .aboutInfo p{
        text-align: justify;
    }


}
@media (max-width: 768px) {
    main .container .box1{
        display: none;
    }
    main .container .box3{
        display: none;
    }

    main .container .box2{
        width: 100%;
    }
    main .container .box2 .undi{
        width: 200px;
    }
    .aboutContainer{
        flex-direction: column;
        padding: 30px;
        gap: 20px;
    }

    .aboutImg{
        width: 100%;
    }

    .aboutInfo{
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .aboutInfo button{
        width: 100%;
    }

    .aboutInfo p{
        text-align: justify;
    }

    .aboutInfo h3{
        text-align: center;
    }
}
@media (max-width: 480px) {
    main .container .box2 .logoImg{
        height: 75px;
    }
    main .container .box2 .undi{
        width: 100px;
    }

    .aboutContainer{
        flex-direction: column;
        padding: 30px;
        gap: 20px;
    }

    .aboutImg{
        width: 100%;
    }

    .aboutInfo{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .aboutInfo h3{
        text-align: center;
    }

    .aboutInfo button{
        width: 100%;
    }

    .aboutInfo p{
        text-align: justify;
    }
}