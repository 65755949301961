@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.option {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
}

.topHeader {
    width: 93%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topHeader .socials {
    /* margin-right: 60px; */
    display: flex;
    gap: 20px;
}

.topHeader .socials i {
    color: #ffffff;
}

.option ul {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
}

.option ul li {
    list-style-type: none;
}

.link-style {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    font-size: 13PX;
}

.link-style p {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-left: 10px;
}

.link-style i {
    color: #ffffff;
}

.header {
    width: 100%;
    height: 110px;
    margin-bottom: -110px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: transparent;
    border-top: 1px solid #eb500e;

}

.logo {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo img {
    width: 150px;
    object-fit: cover;
    /* box-shadow: #ffffff 0px 20px 30px 1px; */
    /* box-shadow: rgba(194, 194, 223, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.navbar {
    width: 65%;
}

.navbar ul {
    display: flex;
    column-gap: 40px;
    align-items: center;
    justify-content: center;
}

.navbar li {
    list-style-type: none;
    font-family: 'Poppins';
    font-weight: 700;

}

.navlink {
    list-style-type: none;
    text-decoration: none;
    color: #ffffff
}

.book {
    width: 20%;
    display: flex;
    column-gap: 25px;
    align-items: center;
    justify-content: center;
}

.book button {
    padding: 10px 15px;
    background-color: transparent;
    border: 2px solid #eb500e;
    color: #eb500e;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    cursor: pointer;

}

.book button:hover {
    background-color: #eb500e;
    color: #ffffff;
}

.book .btn1 {
    background-color: #eb500e;
    border: 2px solid #eb500e;
    color: #ffffff;
}

.book .btn1:hover {
    background-color: transparent;
    color: #eb500e;
}

.book i {
    color: #ffffff;
    font-size: 20px;
}


/* Base styles */
.hamburger {
    display: none;
    cursor: pointer;
    font-size: 25px;
    color: #ffffff;
}

.close{
    display: none;
}

.open{
    display: flex;
}


@media (max-width: 1200px) {
    .topHeader{
        display: none;
    }

    .book{
        display: none;
    }

    .header{
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .navbar{
        width: 100%;
    }
}



@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .navbar ul {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
        row-gap: 15px;
    }

    .navbar.open ul {
        
    }

    .header {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 20px 30px;
    }

    .logo{
        width: 70%;
        justify-content: start;
    }

    .hamburger{
        width: 30%;
        display: flex;
        justify-content: end;
    }

    .navbar{
        /* display: none; */
        width: 0;
    }

    .book {
        width: 100%;
        margin-top: 10px;
        flex-direction: column;
        row-gap: 8px;
    }

    .book button {
        width: 100%;
    }

    .mobileMenu{
        height: 100%;
        flex-direction: column;
        background-color: #eb500e;
        position: fixed;
        top: 0;
        left: 0;
        width: 60vw;
        gap: 20px;
        list-style: none;
        padding: 20px;
    }
    
}

@media (max-width: 480px) {
    .navbar ul {
        row-gap: 10px;
    }

    .book {
        margin-top: 5px;
        row-gap: 5px;
    }
}
