@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.headerBar {
    height: 40vh;
    width: 100%;
    display: flex;
    /* padding-top: 80px; */
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('../../Images/cafeLights.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.headerBar h1 {
    color: #fff;
    font-size: 30px;
    margin-top: 80px;
    border-bottom: 4px solid #eb500e;
    padding-bottom: 10px;
}

.reviewsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 120px;
    gap: 8px;

}

.reviewsContainer img {
    width: 200px;
}

.review {
    display: flex;
    column-gap: 20px;
    row-gap: 70px;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    flex-wrap: wrap;
}

.reviewBox {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url('../../Images/reviewBg.jpg');
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    gap: 18px;
}

.reviewBox i {
    font-size: 30px;
    background-color: #eb500e;
    color: #fff;
    border-radius: 50%;
    padding: 18px;
    object-fit: cover;
    margin-top: -100px;
}

.reviewBox h2 {
    color: #fff;
    font-size: 18px;
}

.reviewBox p {
    color: #fff;
    text-align: center;
    font-size: 13px;
}

@media (max-width: 1200px) {
    .reviewsContainer{
        padding: 40px 30px;
    }
    .reviewsContainer{
        margin: 0;
    }
    .reviewBox{
        width: 30%;
    }
}
@media (max-width: 768px) {
    .reviewsContainer{
        padding: 40px 30px;
    }
    .reviewBox{
        width: 45%;
    }
}
@media (max-width: 480px) {
    .reviewsContainer{
        padding: 40px 30px;
    }
    .reviewBox{
        width: 100%;
    }
} 