@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.headerBar {
    height: 40vh;
    width: 100%;
    display: flex;
    /* padding-top: 80px; */
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('../../Images/cafeLights.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.headerBar h1 {
    color: #fff;
    font-size: 30px;
    margin-top: 80px;
    border-bottom: 4px solid #eb500e;
    padding-bottom: 10px;
}


.contactContainer {
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 40px;
    padding: 60px 0;

}

.formbox1 {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.formbox1 h5 {
    color: #eb500e;
}

.formbox1 img {
    width: 30%
}

.formbox1 .form {
    width: 100%;

}

.formbox1 .form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
}

.formbox1 form input,
textarea {
    border: none;
    width: 100%;

    background-color: #ece6e4;
    color: black;
    font-size: 13px;
    padding: 15px;
}


.formbox1 form button {
    background-color: #eb500e;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: bold;
    padding: 15px;
    display: inline;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.mapContainer {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mapContainer iframe {
    width: 100%;
    height: 70vh;
}

.formbox2 {
    width: 30%;
    /* height: 100%; */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('../../Images/cafeLights.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formbox2 img {
    width: 200px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
}


@media (max-width: 1200px) {}

@media (max-width: 768px) {
    .contactContainer {
        flex-direction: column;
        padding: 60px 40px;
        gap: 30;

    }

    .formbox1 {
        width: 100%;
        padding: 0;
    }

    .formbox1 img {
        width: 50%
    }

    .formbox1 .form {
        padding: 0;
        width: 100%;
    }

    .formbox2 {
        width: 100%;
        height: 400px;
        border-radius: 10px;
    }

    .formbox1 form input,
    textarea {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .contactContainer {
        flex-direction: column;
        padding: 60px 40px;
        gap: 30;

    }

    .formbox1 {
        width: 100%;
        padding: 0;
    }

    .formbox1 img {
        width: 50%
    }

    .formbox1 .form {
        padding: 0;
        width: 100%;
    }

    .formbox2 {
        width: 100%;
        height: 400px;
        border-radius: 10px;
    }

    .formbox1 form input,
    textarea {
        width: 100%;
    }
}