@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.headerBar {
    height: 40vh;
    width: 100%;
    display: flex;
    /* padding-top: 80px; */
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('../../Images/cafeLights.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.headerBar h1 {
    color: #fff;
    font-size: 30px;
    margin-top: 80px;
    border-bottom: 4px solid #eb500e;
    padding-bottom: 10px;
}

.tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 20px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('../../Images/cafeLights.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

/* .tabs button {
    padding: 15px 20px;
    background-color: transparent;
    color: #eb500e;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid #eb500e;

}

.tabs button.active {
    background-color: #eb500e;
    color: #161719;
}

.tabs button:hover {
    background-color: #161719;
    color: #eb500e;
} */

.tabsDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
}

#innerTabDiv {
    width: 15%;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #fff;
    border-right: 5px solid #eb500e;
    gap: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
}


#innerTabDiv:hover {
    background-color: #eb500e
}

#innerTabDiv img {
    width: 100px;
    border-radius: 20px;
}

#innerTabDiv h2 {
    font-size: 15px;
    padding: 20px;
}

.menuContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-wrap: wrap;
}

.menu {
    width: 30%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.menu img {
    width: 40%;
    height: 125px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.menuInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.menuInfo p {
    font-size: 12px;
}

.reviews {
    display: flex;
    justify-content: start;
    align-items: center;
}

.reviews p {
    font-size: 12px;
    ;
    margin-right: 5px;
}

.reviews i {
    font-size: 12px;
    color: gold;
}

@media (max-width: 1200px) {
    #innerTabDiv{
        flex-direction: column;
        padding: 20px;
    }

    #innerTabDiv h2 {
        padding: 0;
    }
    .menu {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .menuContainer {
        gap: 40px;
    }
    .menu {
        width: 100%;
    }
    .menu img {
        width: 40%;
        height: 100%;
    }
    #innerTabDiv{
        width: 40%;
    }
}
@media (max-width: 480px) {
    .menuContainer{
        padding: 60px 20px;
    }
    .menu {
        width: 80%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .menu img {
        width: 100%;
        height: 100%;
    }

    .menuInfo{
        align-items: center;
    }
    #innerTabDiv{
        width: 40%;
    }
}